import React from "react";
import {Animated} from "react-animated-css";


export default class Page8 extends React.Component {

    componentDidMount(){
        this.props.setHeight();
    }

    render(){
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <Animated animationIn="fadeInUpBig" animationOut="fadeOutUpBig" isVisible={this.props.animationVal === 9}>
                    <div className="col-md-12">
                        <div className="">
                                <h1 className="mb-5"> Enjoy! :) </h1>
                            <div className="custom-container mt-5">
                                <div id="wrap">
                                    <div className="steam" id="steam-one"></div>
                                    <div className="steam" id="steam-two"></div>
                                    <div className="steam" id="steam-three"></div>
                                    <div className="steam" id="steam-four"></div>
                                    <div id="cup">
                                        <div id="cup-body">
                                        <div id="cup-shade"></div>
                                    </div>
                                    <div id="cup-handle"></div>
                                    </div>
                                    <div id="saucer"></div>
                                    </div>
                                </div>
                            <div>  
                                <div>
                                    <button onClick={this.props.prevPage} className="mt-5 btn btn-secondary btn-lg"> Previous</button>
                                    <button onClick={this.props.home} className="mt-5 btn btn-warning btn-lg"> Take me to step 1</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Animated>
            </div>
        );
    }
};
import React from "react";
import {Animated} from "react-animated-css";
import waterOnFilter from "../pictures/waterOnFilter.jpg"

const imgHeight = {
    height: "350px"
}

export default class Page4 extends React.Component {

    componentDidMount(){
        this.props.setHeight();
    }

    render(){
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <Animated animationIn="fadeInUpBig" animationOut="fadeOutUpBig" isVisible={this.props.animationVal === 4}>
                    <div className="col-md-12">
                        <div className="">
                            <div>
                            <h2 className="mb-5"> Boil a little bit of water and water the filter </h2>
                            <div class="container mt-5 pt-5 ">
                                <div class="rows drip_blue d-flex">
                                </div>
                                <div class="container rows v60 d-flex col">
                                </div>
                            </div>
                                <div className="row justify-content-sm-center">
                                    <img className="col-md-5 mt-3 rounded" style={imgHeight} src={waterOnFilter} alt="img of pouring water in filter"></img>
                                </div>
                                <div className="d-flex justify-content-center mt-5">
                                    <button onClick={this.props.prevPage} className="mt-5 btn btn-secondary btn-lg"> Previous</button>
                                    <button onClick={this.props.nextPage} className="mt-5 btn btn-success btn-lg"> Done!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
};
import React from "react";
import {Animated} from "react-animated-css";


export default class Page2 extends React.Component {

    componentDidMount(){
        this.props.setHeight();
    }

    render(){
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <Animated animationIn="fadeInUpBig" animationOut="fadeOutUpBig" isVisible={this.props.animationVal === 2}>
                    <div className="col-md-12">
                        <div className="">
                            <div>
                                <h2>How many cups are you brewing?</h2>
                                <select onChange={this.props.change} className="custom-select w-50 mt-5" id="inputGroupSelect01">
                                    <option value>Choose...</option>
                                    <option value="1">One cup</option>
                                    <option value="2">Two cups</option>
                                    <option value="3">Three cups</option>
                                </select>
                                <div className="d-flex justify-content-center mt-5">
                                    <button onClick={this.props.prevPage} className="mt-5 btn btn-secondary btn-lg"> Previous</button>
                                    <button onClick={this.props.nextPage} className="mt-5 btn btn-success btn-lg"> Next!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
};
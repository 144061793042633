import React from "react";
import {Animated} from "react-animated-css";
import bloomCoffe from "../pictures/bloomCoffe.jpg"

const imgHeight = {
    height: "350px"
}

export default class Page7 extends React.Component {

    
    state = {
        counter: 45,
        stopTimerVal: true,
        showStartButton: true,
        showStopButton: false
    }

    timer = (() => {
        
        if(this.state.counter > 0 && this.state.stopTimerVal === false) {
            console.log("About to change the counter and stopTimer is false");
            this.setState({
                counter: this.state.counter - 1
            });
            console.log("i did it");
            
        }
    })

    startTimer = (() => {
        this.setState({
            stopTimerVal: false,
            showStartButton: false,
            showStopButton: true,
        });
        this.myTimer = setInterval(this.timer, 1000);
        
        
    })


    restartTimer = (() => {
        this.setState({
            counter: 45
        });
        console.log(this.state.counter);
    })
    stopTimer = (() => {
        this.setState({
            stopTimerVal: true,
            showStopButton: false,
            showStartButton: true
        });
        clearInterval(this.myTimer);
        console.log("Just cleared the interval");
    });

    componentDidMount(){
        this.props.setHeight();
    }

    render(){
        const startTimerButton = <button onClick={this.startTimer} className="mt-5 btn btn-primary btn-lg"> Start timer</button>;
        const stopTimerButton = <button onClick={this.stopTimer} className="mt-5 btn btn-secondary btn-lg"> Stop timer</button>;
        const restartTimerButton = <button onClick={this.restartTimer} className="mt-5 btn btn-warning btn-lg"> Restart timer</button>;

        return (
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <Animated animationIn="fadeInUpBig" animationOut="fadeOutUpBig" isVisible={this.props.animationVal === 7}>
                    <div className="col-md-12">
                        <div className="">
                            <div>
                                <h3> Bloom the coffe with {this.props.cups * 50} grams (milliliter) of water. Pour the water using the kettle in a circular motion, starting from the outside in. When 
                                finished click the timer button and wait for the bloom to finish.</h3>
                                <div className="row justify-content-sm-center mt-3">
                                    <img className="col-xs-3 col-sm-5 mt-5 rounded" style={imgHeight} src={bloomCoffe} alt="img of coffe in v60 filter"></img>
                                </div>
                                {this.state.showStartButton ? startTimerButton : null}
                                {this.state.showStopButton && this.state.counter > 0 ? stopTimerButton : null}
                                {this.state.counter === 0 ? restartTimerButton : null}
                                <Animated animationIn="fadeInUpBig" animationOut="fadeOutUpBig" isVisible={true}>
                                    <h1 className="pt-5">{this.state.counter} </h1>
                                </Animated>
                                <div>
                                    <button onClick={this.props.prevPage} className="mt-5 btn btn-secondary btn-lg"> Previous</button>
                                    <button onClick={this.props.nextPage} className="mt-5 btn btn-success btn-lg"> Done!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
};

// Chattfönster =

// Teo's kommentarer:
    // Börja arbeta i page2 med inputfielsen för hur många koppar man ska göra. Se till att den uppdaterar statet "cups"
    // Nästa steg är att uppdatera de andra componenterna baserat på värdet av "cups"

// Marvin's kommentarer:
    // Göra en funktion som ser om container divven är större än vh-100, beroende på vad så lägger den till antingen klassen vh-100 eller h-100.
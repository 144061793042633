import React from "react";
import {Animated} from "react-animated-css";
import grinder from "../pictures/coffeGrinder.jpg";
import grindedCoffe from "../pictures/grindedCoffe.jpeg"

const imgHeight = {
    height: "350px"
}

export default class Page3 extends React.Component {

    componentDidMount(){
        this.props.setHeight();
    }

    render(){
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <Animated animationIn="fadeInUpBig" animationOut="fadeOutUpBig" isVisible={this.props.animationVal === 3}>
                    <div className="col-md-12">
                        <div className="">
                            <div>
                                <h2> Weigh {this.props.cups * 15} grams of coffee and grind it with the grind of "filter" or "medium", look at the pictures below.</h2>
                                <div className="custom-container">
                                    <div className="container">
                                        <div className="rows beans">
                                        </div>
                                        <div className="container rows grinder">
                                        </div>
                                        <div className="rows grinder_bottom">
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-sm-center mt-5">
                                    <img className="col-md-4 pr-4 mt-5 rounded" style={imgHeight} src={grinder} alt="img of grinder"></img>
                                    <img className="col-md-4 pr-4 mt-5 rounded" style={imgHeight} src={grindedCoffe} alt="img of grinded coffe"></img>
                                </div>
                                <div className="d-flex justify-content-center mt-5">
                                    <button onClick={this.props.prevPage} className="mt-5 btn btn-secondary btn-lg"> Previous</button>
                                    <button onClick={this.props.nextPage} className="mt-5 btn btn-success btn-lg"> Done!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
};
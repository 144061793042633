import React from "react";
import {Animated} from "react-animated-css";

export default class Page6 extends React.Component {

    componentDidMount(){
        this.props.setHeight();
    }

    render(){
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <Animated animationIn="fadeInUpBig" animationOut="fadeOutUpBig" isVisible={this.props.animationVal === 6}>
                    <div className="col-md-12">
                        <div className="">
                            <div>
                                <h1> Boil {this.props.cups * 250} grams (milliliter) of water.</h1>
                                <div>
                                    <button onClick={this.props.prevPage} className="mt-5 btn btn-secondary btn-lg"> Previous</button>
                                    <button onClick={this.props.nextPage} className="mt-5 btn btn-success btn-lg"> Done!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
};
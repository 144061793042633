import React from "react";
import picture1 from "../pictures/Slice.png";
import picture2 from "../pictures/Slice2.png";
import picture3 from "../pictures/Slice3.png";
import {Animated} from "react-animated-css";

const imgHeight = {
    height: "350px"
}

export default class Page1 extends React.Component {

    componentDidMount(){
        this.props.setHeight();
    }

    render(){
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <Animated animationIn="fadeInUpBig" animationOut="fadeOutUpBig" isVisible={this.props.animationVal === 1}>
                    <div className="col-md-12">
                        <div className="">
                            <div className="">
                                <h1 className="display-5">Welcome to:<br></br> How To Pour Over!</h1>
                                <h5 className="my-5">To brew a pour-over you will need:</h5>
                                <div className="row">
                                    <div styleclassName="col" >
                                        <img style={imgHeight} src={picture1} alt="img of kettle"></img>
                                        <p>A kettle</p>
                                    </div>
                                    <div className="col" >
                                        <img style={imgHeight} src={picture2} alt="img of pour over kit"></img>
                                        <p>A Pour over kit</p>
                                    </div>
                                    <div className="col">
                                        <img style={imgHeight} src={picture3} alt="img of grinder"></img>
                                        <p>A grinder</p>
                                    </div>
                                </div>
                                <button onClick={this.props.nextPage} className="mt-5 btn btn-success btn-lg"> Got it!</button>
                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
};


import React from 'react';
import './App.css';
import Page1 from "./components/Page1.js";
import Page2 from "./components/Page2.js";
import Page3 from "./components/Page3.js";
import Page4 from "./components/Page4.js";
import Page5 from "./components/Page5.js";
import Page6 from "./components/Page6.js";
import Page7 from "./components/Page7.js";
import Page8 from "./components/Page8.js";
import Page9 from "./components/Page9.js";

export default class App extends React.Component{

  state = {
    page: 1,
    cups: null,
    animationVal: 1
  }

  nextPage = () => {
    this.setState({
      animationVal: this.state.animationVal + 1
    });
    setTimeout(() => {
      window.scroll(0,0)
      this.setState({
        page: this.state.page + 1
      })
    }, 120)
  }
  
  prevPage = () => {
    this.setState({
      animationVal: this.state.animationVal - 1
    });
    setTimeout(() => {
      window.scroll(0,0)
      this.setState({
        page: this.state.page - 1
      })
    }, 120);
  }

  home = () => {
    this.setState({
      animationVal: 1
    });
    setTimeout(() => {
      this.setState({
        page: this.state.page - 8
      })
    }, 120)
  }
  
  change = (event) => {
    this.setState({
      cups: parseInt(event.target.value)
    })
  }

  setHeight = (() => {
    setTimeout(() => {
      let appHeight = document.getElementsByClassName("App")[0];
      let containerDiv = document.getElementsByClassName("container-fluid")[0];
      let vhHeight = document.documentElement.clientHeight;
      console.log(containerDiv.offsetHeight);
      console.log(vhHeight);
      
      if (appHeight.offsetHeight > vhHeight) {
        containerDiv.classList.add("h-100", "py-5");
      }
      else {
        containerDiv.classList.add("vh-100", "py-5")
      }
    }, 200)
    
  })

  render() {
    const page = this.state.page

    if(page === 1){
      return (
        <div className="App">
          <Page1 setHeight={this.setHeight} nextPage={this.nextPage} animationVal={this.state.animationVal}/>
        </div>
      )
    }

    if(page === 2){
      return (
        <div className="App">
          <Page2 setHeight={this.setHeight} nextPage={this.nextPage} prevPage={this.prevPage} change={this.change} animationVal={this.state.animationVal}/>
        </div>
      )
    }

    if(page === 3){
      return (
        <div className="App">
          <Page3 setHeight={this.setHeight} nextPage={this.nextPage} prevPage={this.prevPage} cups={this.state.cups} animationVal={this.state.animationVal}/>
        </div>
      )
    }
    
    if(page === 4){
      return (
        <div className="App">
          <Page4 setHeight={this.setHeight} nextPage={this.nextPage} prevPage={this.prevPage} animationVal={this.state.animationVal}/>
        </div>
      )
    }

    if(page === 5){
      return (
        <div className="App">
          <Page5 setHeight={this.setHeight} nextPage={this.nextPage} prevPage={this.prevPage} animationVal={this.state.animationVal}/>
        </div>
      )
    }

    if(page === 6){
      return (
        <div className="App">
          <Page6 setHeight={this.setHeight} nextPage={this.nextPage} prevPage={this.prevPage} cups={this.state.cups} animationVal={this.state.animationVal}/>
        </div> 
      )
    }

    if(page === 7){
      return (
        <div className="App">
          <Page7 setHeight={this.setHeight} nextPage={this.nextPage} prevPage={this.prevPage} cups={this.state.cups} animationVal={this.state.animationVal}/>
        </div>
      )
    }

    if(page === 8){
      return (
        <div className="App">
          <Page8 setHeight={this.setHeight} nextPage={this.nextPage} prevPage={this.prevPage} cups={this.state.cups} animationVal={this.state.animationVal}/>
        </div>
      )
    }

    if(page === 9){
      return (
        <div className="App">
          <Page9 setHeight={this.setHeight} home={this.home} prevPage={this.prevPage} animationVal={this.state.animationVal}/>
        </div>
      )
    }
  }
};

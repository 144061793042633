import React from "react";
import {Animated} from "react-animated-css";
import pourOver from "../pictures/pourOver.jpg"

const imgHeight = {
    height: "350px"
}

export default class Page9 extends React.Component {

    componentDidMount(){
        this.props.setHeight();
    }

    render(){
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <Animated animationIn="fadeInUpBig" animationOut="fadeOutUpBig" isVisible={this.props.animationVal === 8}>
                    <div className="col-md-12">
                        <div className="">
                            <div>  
                                <h2> Pour {this.props.cups * 250 - this.props.cups * 50} grams (milliliter) of water in a circular motion starting from the outside in </h2>
                                <div className="row justify-content-sm-center mt-3">
                                    <img className="col-xs-3 col-sm-5 mt-5 rounded" style={imgHeight} src={pourOver} alt="img of coffe in v60 filter"></img>
                                </div>
                                <div>
                                    <button onClick={this.props.prevPage} className="mt-5 btn btn-secondary btn-lg"> Previous</button>
                                    <button onClick={this.props.nextPage} className="mt-5 btn btn-success btn-lg"> Done!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
};